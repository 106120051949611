import events from '..';
import track from '../gtm';

function trackPageView(event) {
  track(event.data);
  return event;
}
trackPageView.eventType = events.pageView;

export default trackPageView;
