import React from 'react';
import { Provider } from 'react-redux';
import App, { Container } from 'next/app';
import Head from 'next/head';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import getConfig from 'next/config';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faInfoCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Tracker, TrackerProvider } from 'react-tracker';
import { GoogleTagManager } from 'features/_embedded';
import { connectWithRedux } from 'store';
import { liteTheme } from 'layouts';
import trackers from 'utils/tracking/listeners';
import { effects as userSessionEffects } from 'store/user-session';

library.add(faCircleNotch, faInfoCircle, faSyncAlt);

const tracker = new Tracker([...trackers]);
const { publicRuntimeConfig } = getConfig();

const gridTheme = {
  row: {
    padding: 0,
  },
};

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Rubik:400,700');
  @import url('https://fonts.googleapis.com/css?family=Roboto');

  body {
    font-family: 'Rubik', sans-serif;
    height: stretch;
    width: 100vw;
    margin: 0;

    #__next {
      height: 100%;
    }
  }

  input {
    font-family: 'Roboto', sans-serif;
  }
`;

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const { req, store } = ctx;

    if (req && store) {
      store.dispatch(userSessionEffects.syncUserSession(req));
    }

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    return { pageProps };
  }

  componentDidMount() {
    if (publicRuntimeConfig.SENTRY_DNS_CLIENT) {
      import('@sentry/browser').then((Sentry) => {
        Sentry.init({
          dsn: publicRuntimeConfig.SENTRY_DNS_CLIENT,
          release: publicRuntimeConfig.VERSION,
          ignoreErrors: [
            "Can't execute code from freed script",
            /SecurityError: DOM Exception 18$/,
          ],
          whitelistUrls: [
            /^https?:\/\/([a-zA-Z\d-]+\.){0,}arquivei\.com\.br.*$/,
          ],
        });

        Sentry.configureScope((scope) => {
          // eslint-disable-next-line no-underscore-dangle
          scope.setExtra('next_data', window.__NEXT_DATA__);
        });
      });
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;

    const javascripts = [
      'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js',
      `https://stc.${publicRuntimeConfig.PAGSEGURO_ENVIRONMENT}pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js`,
    ];

    const gtmId = publicRuntimeConfig.GTM_ID;

    return (
      <Container>
        <Head>
          <title>
            Arquivei Lite - Baixar XML/DANFe de NFe por Chave de Acesso
          </title>
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="/static/favicon.ico"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="description"
            content="Ferramenta grátis para consultar e baixar XMLs e DANFes de Notas Fiscais por Chave de Acesso em lote, sem Certificado Digital. Oferece integração com sistemas."
          />
          <meta
            property="og:title"
            content="Arquivei Lite - Baixar XML/DANFe de NFe por Chave de Acesso"
          />
          <meta property="og:url" content="https://lite.arquivei.com.br" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://d265gkomn4fklo.cloudfront.net/blog/wp-content/uploads/2018/12/Thumbnail-LP-ArquiveiLite.png"
          />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
          <meta
            property="og:description"
            content="Ferramenta grátis para consultar e baixar XMLs e DANFes de Notas Fiscais por Chave de Acesso em lote, sem Certificado Digital. Oferece integração com sistemas."
          />

          {gtmId && <GoogleTagManager id={gtmId} />}

          <link
            rel="stylesheet"
            type="text/css"
            href="/static/font-awesome.css"
          />
          <script
            src="https://www.google.com/recaptcha/api.js?render=explicit&hl=pt-BR"
            async
            defer
          />
          {javascripts.map((src) => (
            <script key={src} src={src} />
          ))}
          <GlobalStyle />
        </Head>
        <Provider store={store}>
          <ThemeProvider theme={liteTheme}>
            <GridThemeProvider gridTheme={gridTheme}>
              <TrackerProvider tracker={tracker}>
                <Component {...pageProps} />
              </TrackerProvider>
            </GridThemeProvider>
          </ThemeProvider>
        </Provider>
      </Container>
    );
  }
}

export default connectWithRedux(MyApp);
