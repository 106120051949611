import events from '..';
import track from '../gtm';

function trackBatchDownloadZipClick(event) {
  track(event.data);
  return event;
}
trackBatchDownloadZipClick.eventType = events.batchDownloadZip;

export default trackBatchDownloadZipClick;
