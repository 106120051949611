import events from '..';
import track from '../gtm';

function trackBatchConsultButtonCancel(event) {
  track(event.data);
  return event;
}
trackBatchConsultButtonCancel.eventType = events.batchConsultCancel;

export default trackBatchConsultButtonCancel;
