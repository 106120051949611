import events from '..';
import track from '../gtm';

function trackBatchConsultSuccess(event) {
  track(event.data);
  return event;
}
trackBatchConsultSuccess.eventType = events.batchConsultSuccess;

export default trackBatchConsultSuccess;
