import { fromJS } from 'immutable';

export const serialize = (state) =>
  !state
    ? state
    : Object.keys(state).reduce(
        (newState, key) => ({ ...newState, [key]: state[key].toJS() }),
        {},
      );

export const deserialize = (state) =>
  !state
    ? state
    : Object.keys(state).reduce(
        (newState, key) => ({ ...newState, [key]: fromJS(state[key]) }),
        {},
      );
