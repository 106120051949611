import events from '..';
import track from '../gtm';

function trackExternalArquiveiClick(event) {
  track(event.data);
  return event;
}
trackExternalArquiveiClick.eventType = events.externalArquivei;

export default trackExternalArquiveiClick;
