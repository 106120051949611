import events from '..';
import track from '../gtm';

function trackRouteToBatch(event) {
  track(event.data);
  return event;
}
trackRouteToBatch.eventType = events.routeToBatch;

export default trackRouteToBatch;
