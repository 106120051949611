import events from '..';
import track from '../gtm';

function trackSingleConsultDownloadXmlClick(event) {
  track(event.data);
  return event;
}
trackSingleConsultDownloadXmlClick.eventType = events.singleConsultDownloadXml;

export default trackSingleConsultDownloadXmlClick;
