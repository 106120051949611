import events from '..';
import track from '../gtm';

function trackSingleConsultError(event) {
  track(event.data);
  return event;
}
trackSingleConsultError.eventType = events.singleConsultError;

export default trackSingleConsultError;
