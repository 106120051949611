import events from '..';
import track from '../gtm';

function trackSingleConsultDownloadPdfClick(event) {
  track(event.data);
  return event;
}
trackSingleConsultDownloadPdfClick.eventType = events.singleConsultDownloadPdf;

export default trackSingleConsultDownloadPdfClick;
