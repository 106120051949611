import events from '..';
import track from '../gtm';

function trackOpenIntercomClick(event) {
  track(event.data);
  return event;
}
trackOpenIntercomClick.eventType = events.openIntercom;

export default trackOpenIntercomClick;
