import events from '..';
import track from '../gtm';

function trackSingleConsultSuccess(event) {
  track(event.data);
  return event;
}
trackSingleConsultSuccess.eventType = events.singleConsultSuccess;

export default trackSingleConsultSuccess;
