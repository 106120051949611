import events from '..';
import track from '../gtm';

function trackSingleConsultButtonClick(event) {
  track(event.data);
  return event;
}
trackSingleConsultButtonClick.eventType = events.singleConsult;

export default trackSingleConsultButtonClick;
