import events from '..';
import track from '../gtm';

function trackBatchConsultError(event) {
  track(event.data);
  return event;
}
trackBatchConsultError.eventType = events.batchConsultError;

export default trackBatchConsultError;
