import batchConsult from './batchConsult';
import batchConsultCancel from './batchConsultCancel';
import batchConsultError from './batchConsultError';
import batchConsultPreConfirm from './batchConsultPreConfirm';
import batchConsultSuccess from './batchConsultSuccess';
import batchDownloadZip from './batchDownloadZip';
import buyMoreCredits from './buyMoreCredits';
import externalArquivei from './externalArquivei';
import openIntercom from './openIntercom';
import pageView from './pageView';
import routeToBatch from './routeToBatch';
import singleConsult from './singleConsult';
import singleConsultDownloadPdf from './singleConsultDownloadPdf';
import singleConsultDownloadXml from './singleConsultDownloadXml';
import singleConsultError from './singleConsultError';
import singleConsultSuccess from './singleConsultSuccess';

export default [
  batchConsult,
  batchConsultCancel,
  batchConsultError,
  batchConsultPreConfirm,
  batchConsultSuccess,
  batchDownloadZip,
  buyMoreCredits,
  externalArquivei,
  openIntercom,
  pageView,
  routeToBatch,
  singleConsult,
  singleConsultDownloadPdf,
  singleConsultDownloadXml,
  singleConsultError,
  singleConsultSuccess,
];
