import events from '..';
import track from '../gtm';

function trackBatchConsultButtonPreConfirm(event) {
  track(event.data);
  return event;
}
trackBatchConsultButtonPreConfirm.eventType = events.batchConsultPreConfirm;

export default trackBatchConsultButtonPreConfirm;
