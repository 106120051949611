import events from '..';
import track from '../gtm';

function trackBuyMoreCreditsClick(event) {
  track(event.data);
  return event;
}
trackBuyMoreCreditsClick.eventType = events.buyMoreCredits;

export default trackBuyMoreCreditsClick;
