import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import withRedux from 'next-redux-wrapper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';

import { deserialize, serialize } from 'utils/state-serialization';

import batch from './batch';
import notificationCard from './notification-card-deprecated';
import singleKeyQuery from './single-key-query';
import userAuthentication from './user-authentication';
import userSession from './user-session';

const isDev = String(process.env.NODE_ENV) === 'development';

const reducers = combineReducers({
  batch,
  notificationCard,
  singleKeyQuery,
  userAuthentication,
  userSession,
});

const makeStore = (initialState) =>
  createStore(
    reducers,
    deserialize(initialState),
    isDev
      ? composeWithDevTools(applyMiddleware(thunkMiddleware))
      : applyMiddleware(thunkMiddleware),
  );

const customSerialization = {
  serializeState: (state) => serialize(state),
  deserializeState: (state) => deserialize(state),
};

export const connectWithRedux = (App) =>
  withRedux(makeStore, customSerialization)(App);
